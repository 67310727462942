<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div id="js_article" style="position:relative;" class="rich_media">

      <div id="js_top_ad_area" class="top_banner"></div>

      <div id="js_base_container" class="rich_media_inner">


        <div id="page-content" class="rich_media_area_primary" style="">
          <div class="rich_media_area_primary_inner">


            <div id="img-content" class="rich_media_wrp">

              <h1 class="rich_media_title " id="activity-name">

                <el-link :href="yourExternalPageURL" class="rich_media_title"
                  target="_blank">河南省放射影像质控中心2024年第一次专题培训会议圆满召开（转载）</el-link>
              </h1>
              <div id="meta_content" class="rich_media_meta_list">
                <span class="rich_media_meta rich_media_meta_text">
                  刘杰
                </span>

                <span class="rich_media_meta rich_media_meta_nickname" id="profileBt">
                  <a href="javascript:void(0);" class="wx_tap_link js_wx_tap_highlight weui-wa-hotarea" id="js_name">
                    河医医学影像之家 </a>

                  <div data-v-ca5b81fd="" id="js_profile_qrcode" class="profile_card_container" style="display: none;">
                    <div data-v-ca5b81fd="" class="profile_card_wrap">
                      <div data-v-ca5b81fd="" class="profile_card_qrcode"><img data-v-ca5b81fd=""
                          id="js_profile_qrcode_img"
                          src="/mp/qrcode?scene=10000005&amp;size=102&amp;__biz=Mzg3MDc1Mjg4OA==&amp;mid=2247485639&amp;idx=1&amp;sn=b6b06e300fd30c02b3f7be5033c240b8&amp;send_time="
                          alt="profile_qrcode" class="profile_qrcode_img"></div>
                      <div data-v-ca5b81fd="" class="profile_card_info">
                        <div data-v-ca5b81fd=""><span data-v-ca5b81fd="" class="profile_info_nickname"> 河医医学影像之家 </span>
                        </div><!---->
                        <div data-v-ca5b81fd="" class="profile_card_info_meta">
                          <p data-v-ca5b81fd="" class="profile_card_info_meta_text profile_card_signature">
                            郑州大学第一附属医院放射科倾力打造，科普关于普放、乳腺钼靶、CT、肿瘤微创等医学影像相关的医疗、技术及护理方面的知识，推送新鲜资讯、热点！关爱公众健康！ </p>
                        </div>
                        <p data-v-ca5b81fd="" class="profile_original_count profile_card_info_meta"><span
                            data-v-ca5b81fd="" class="profile_card_info_meta_text">81篇原创内容</span></p>
                      </div>
                    </div>
                    <div data-v-ca5b81fd="" id="js_profile_arrow_wrp" class="profile_arrow_wrp" style="left: 54px;"><i
                        data-v-ca5b81fd="" class="profile_arrow arrow_out"></i><i data-v-ca5b81fd=""
                        class="profile_arrow arrow_in"></i></div>
                  </div>
                </span>

                <span id="meta_content_hide_info" class="">
                  <em id="publish_time" class="rich_media_meta rich_media_meta_text">2024年07月03日 14:57</em>
                  <em id="js_ip_wording_wrp" class="rich_media_meta rich_media_meta_text" role="option"
                    aria-labelledby="js_a11y_op_ip_wording js_ip_wording" style="display: inline-block;"><span
                      id="js_a11y_op_ip_wording" aria-hidden="true"></span><span aria-hidden="true"
                      id="js_ip_wording">河南</span></em>

                </span>
              </div>


              <div class="rich_media_content js_underline_content
                       autoTypeSetting24psection
            " id="js_content" style="">
                <section style="text-indent: 2em; visibility: visible;"><span style="visibility: visible;">6</span><span
                    style="font-family: 宋体; visibility: visible;">月</span><span
                    style="visibility: visible;">22-23</span><span
                    style="font-family: 宋体; visibility: visible;">日，在河南省卫生健康委员会的大力支持下，河南省放射影像质控中心成功召开了第一次质控专题培训会。此次会议旨在进一步推动河南省放射影像质控工作的规范化、标准化，确保患者得到高质量的医疗服务。</span><span
                    style="visibility: visible;">
                  </span></section>
                <section style="text-indent: 2em; visibility: visible;"><span
                    style="font-family: 宋体; visibility: visible;">会议邀请了国家放射影像质控中心主任委员、北京协和医院金征宇教授，中华医学会影像技术分会前主任委员、北京协和医院付海鸿教授，河南省卫生健康委员会医政医管处王仲阳处长，河南省放射影像质控中心主任委员、郑州大学第一临床医学院执行院长高剑波教授，以及放射影像质控中心副主任委员、各位委员和来自全省各地市的放射影像质控专家和医院代表共计</span><span
                    style="visibility: visible;">200</span><span
                    style="font-family: 宋体; visibility: visible;">余人参加本次培训班。</span><span style="visibility: visible;">
                  </span></section>
                <section style="text-indent: 2em; visibility: visible;"><span
                    style="font-family: 宋体; visibility: visible;">会议开幕式由河南省放射影像质控中心副主任委员、河南省人民医院朱绍成教授主持，首先河南省放射影像质控中心主任委员高剑波教授在开幕致辞中欢迎各位领导来到河南，支持河南的质控工作，并强调了质控工作对于保障患者安全、提高医疗质量的重要性。他表示，质控中心将积极履行职责，加强与各医院的沟通与合作，共同推动质控工作的深入开展。</span>
                </section>
                <p style="text-align: center; visibility: visible;"><img class="rich_pages wxw-img" data-galleryid=""
                    data-imgfileid="100001979" data-ratio="0.6658653846153846" data-s="300,640"
                    data-src="@/assets/HY20240723/1.png" data-type="jpeg" data-w="832"
                    style="height: auto !important; visibility: visible !important; width: 677px !important;"
                    data-original-style="" data-index="1" src="@/assets/HY20240723/1.png" _width="677px"
                    crossorigin="anonymous" alt="图片" data-fail="0"><span
                    style="font-family: 宋体;font-size: var(--articleFontsize);letter-spacing: 0.034em;text-align: justify;">朱绍成教授</span>
                </p>
                <p style="text-align: center;"><img class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-galleryid="" data-imgfileid="100001980" data-ratio="0.6655251141552512" data-s="300,640"
                    data-src="@/assets/HY20240723/2.png" data-type="jpeg" data-w="876"
                    style="width: 677px !important; height: 450.561px !important;" data-original-style="" data-index="2"
                    src="@/assets/HY20240723/2.png" _width="677px" crossorigin="anonymous" alt="图片"><span
                    style="font-family: 宋体;font-size: var(--articleFontsize);letter-spacing: 0.034em;text-align: justify;">高剑波教授致辞</span>
                </p>
                <section style="text-indent: 2em;"><span
                    style="font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin;">国家放射影像质控中心主任委员金征宇教授指出随着医疗技术的不断进步和患者需求的不断提高，质控工作将面临更大的挑战和机遇。他建议质控中心加强与其他医疗机构的合作与交流，积极引进和应用新技术、新方法，提高质控工作的效率和质量。河南省放射影像质控中心提供了重要的交流平台和机会，也为质控中心未来的工作指明了方向和目标。相信在全省各医疗机构和质控中心的共同努力下，河南省放射影像质控工作将不断取得新的进展和成就。</span>
                </section>
                <p style="text-align: center;"><img class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-galleryid="" data-imgfileid="100001982" data-ratio="0.6663179916317992" data-s="300,640"
                    data-src="@/assets/HY20240723/3.png" data-type="jpeg" data-w="956"
                    style="width: 677px !important; height: 451.097px !important;" data-original-style="" data-index="3"
                    src="@/assets/HY20240723/3.png" _width="677px" crossorigin="anonymous" alt="图片"><span
                    style="font-family: 宋体;font-size: var(--articleFontsize);letter-spacing: 0.034em;text-align: justify;">金征宇教授致辞</span>
                </p>
                <section style="text-indent: 2em;"><span
                    style="font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin;">河南省卫生健康委员会医政医管处王仲阳处长对放射影像质控中心开展专题培训班表示祝贺，并对质控中心未来的工作提出了要求和期望。他表示，质控中心要继续加强组织建设和人员培训，提高质控工作的专业性和权威性；同时，要密切关注医疗技术的发展和患者需求的变化，及时调整质控工作的方向和重点。</span>
                </section>
                <p style="text-align: center;"><img class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-galleryid="" data-imgfileid="100001981" data-ratio="0.6655132641291811" data-s="300,640"
                    data-src="@/assets/HY20240723/4.png" data-type="jpeg" data-w="867"
                    style="width: 677px !important; height: 450.552px !important;" data-original-style="" data-index="4"
                    src="@/assets/HY20240723/4.png" _width="677px" crossorigin="anonymous" alt="图片"><span
                    style="font-family: 宋体;font-size: var(--articleFontsize);letter-spacing: 0.034em;text-align: justify;">王仲阳处长致辞</span>
                </p>
                <section style="text-indent: 2em;"><span
                    style="font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin;">会议中，来自北京协和医院的付海鸿教授、孙昊教授进行了培训班的专家讲座，着重讲述了国家放射影像质控中心的工作计划和体系建设；高剑波教授介绍河南省放射影像质控的现状与展望；岳松伟教授解读了国家质控中心最新颁布的质控指标；刘杰教授利用质量管理工作汇报了降低</span><span>CT</span><span
                    style="font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin;">增强对比剂渗漏率的具体工作方案，会议培训内容紧抓质量控制与管理，参会代表和与会专家就质控工作的具体实践进行了分享和交流。并结合自身的经验和案例，探讨了质控工作中的难点和重点，并提出了相应的解决方案和建议，这些分享和交流为参会人员提供了宝贵的参考和启示。</span>
                </section>
                <p style="text-align: center;"><img class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-galleryid="" data-imgfileid="100001984" data-ratio="0.6666666666666666" data-s="300,640"
                    data-src="@/assets/HY20240723/5.png" data-type="jpeg" data-w="963"
                    style="width: 677px !important; height: 451.333px !important;" data-original-style="" data-index="5"
                    src="@/assets/HY20240723/5.png" _width="677px" crossorigin="anonymous" alt="图片"><span
                    style="font-family: 宋体;font-size: var(--articleFontsize);letter-spacing: 0.034em;text-align: justify;">付海鸿教授讲座</span>
                </p>
                <p style="text-align: center;"><img class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-galleryid="" data-imgfileid="100001983" data-ratio="0.6670951156812339" data-s="300,640"
                    data-src="@/assets/HY20240723/6.png" data-type="jpeg" data-w="778"
                    style="width: 677px !important; height: 451.623px !important;" data-original-style="" data-index="6"
                    src="@/assets/HY20240723/6.png" _width="677px" crossorigin="anonymous" alt="图片"><span
                    style="font-family: 宋体;font-size: var(--articleFontsize);letter-spacing: 0.034em;text-align: justify;">孙昊教授讲座</span>
                </p>
                <p style="text-align: center;"><img class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-galleryid="" data-imgfileid="100001986" data-ratio="0.6663179916317992" data-s="300,640"
                    data-src="@/assets/HY20240723/7.png" data-type="jpeg" data-w="956"
                    style="width: 677px !important; height: 451.097px !important;" data-original-style="" data-index="7"
                    src="@/assets/HY20240723/7.png" _width="677px" crossorigin="anonymous" alt="图片"><span
                    style="font-family: 宋体;font-size: var(--articleFontsize);letter-spacing: 0.034em;text-align: justify;">高剑波教授讲座</span>
                </p>
                <p style="text-align: center;"><img class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-galleryid="" data-imgfileid="100001987" data-ratio="0.6663168940188877" data-s="300,640"
                    data-src="@/assets/HY20240723/8.png" data-type="jpeg" data-w="953"
                    style="width: 677px !important; height: 451.097px !important;" data-original-style="" data-index="8"
                    src="@/assets/HY20240723/8.png" _width="677px" crossorigin="anonymous" alt="图片"><span
                    style="letter-spacing: 0.034em;text-align: justify;">岳</span><span
                    style="letter-spacing: 0.034em;text-align: justify;">松伟教授讲座</span></p>
                <p style="text-align: center;"><img class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-galleryid="" data-imgfileid="100001988" data-ratio="0.6666666666666666" data-s="300,640"
                    data-src="@/assets/HY20240723/9.png" data-type="jpeg" data-w="942"
                    style="width: 677px !important; height: 451.333px !important;" data-original-style="" data-index="9"
                    src="@/assets/HY20240723/9.png" _width="677px" crossorigin="anonymous" alt="图片"><span
                    style="font-family: 宋体;font-size: var(--articleFontsize);letter-spacing: 0.034em;text-align: justify;">刘杰教授讲座</span>
                </p>
                <section style="text-indent: 2em;"><span>2024</span><span
                    style="font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin;">年河南省放射影像质控中心第一次专题培训会议的成功举办，不仅为与会专家与代表们提供了深入交流的机会，也为河南省放射影像质控工作的发展注入了新的活力。会议结束后，各医院和机构纷纷表示将积极响应质控中心的号召，共同推动质控工作的深入实施。</span><span>
                  </span></section>
                <section style="text-indent: 2em;"><span
                    style="font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin;">我们相信在河南省卫生健康委员会医政医管处的领导下，在全省各医疗机构和质控中心的共同努力下，河南省放射影像质控工作将不断取得新的进展和成就，质控中心将携手各医院和机构，共同推动河南省放射影像质控工作迈向更高的台阶，为广大患者提供更加安全、优质的医疗服务。</span><span>
                  </span></section>
                <p style="margin-bottom: 0px;"><br></p>
              </div>

              
            </div>
            <div id="js_tags_preview_toast" class="article-tag__error-tips" style="display: none;">预览时标签不可点</div>

            <div id="content_bottom_area"><!----><!----><!---->
              <div data-len="0" class="article-tag__list" style="display: none;">
                <div class="article-tags"></div>
              </div>
              <div class="appmsg_card_context album_read_card" style="display: none;"><!----></div>
              <div class="rich_media_tool_area" style="display: none;">
                <div class="rich_media_tool__wrp">
                  <div id="js_content_end" class="rich_media_tool">
                    <div class="rich_media_info weui-flex policy_tips tips_global_primary"><!----></div>
                    <div class="rich_media_info weui-flex"><!----><!----><!----></div>
                    <div id="js_bottom_opr_right" class="weui-flex sns_opr_area" style="display: none;"><!----></div>
                  </div><!----><!---->
                </div>
              </div>
            </div>


            <div id="js_temp_bottom_area" class="rich_media_tool_area">

              <div class="rich_media_tool__wrp">
                <div class="rich_media_tool">

                  <div class="rich_media_info weui-flex policy_tips tips_global_primary">
                  </div>

                  <div class="rich_media_info weui-flex ">



                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "HY20240723",
  data() {
    return {
      yourExternalPageURL: "https://mp.weixin.qq.com/s/08RUHCSBo524a4_xCYmySg",
    }
  },
}
</script>

<style scoped>
.card_box {
  width: 350px;
  cursor: pointer;
}

.card_img {
  width: 300px;
}

.rich_media_area_primary_inner {
  max-width: 677px;
  margin: 0 auto;
}

.rich_media_meta_list {
  margin-bottom: 22px;
  line-height: 20px;
  font-size: 0;
  word-wrap: break-word;
  hyphens: auto;
}

.rich_media_meta {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px 0;
  font-size: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: #576b95;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rich_media_meta_list em {
  font-style: normal;
}

.rich_media_meta_text {
  color: rgba(255, 255, 255, .35);
  color: rgba(0, 0, 0, .3);
}

.rich_media_title {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
}

.autoTypeSetting24psection>section {
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.5;
  letter-spacing: 1px;
}
.rich_media_content {
  color: rgba(0, 0, 0, .9);
  font-size: 17px;
  overflow: hidden;
  text-align: justify;
}
#js_article {
  background: #fff;
}
</style>